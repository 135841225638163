<template>
  <div class="home">
    <!-- Popup Image Dialog -->
    <v-dialog v-model="showImagePopup" persistent max-width="500">
      <v-card>
        <!-- <v-card-title class="text-h5">Selamat Datang!</v-card-title> -->
        <v-card-text>
          <!-- Popup Image -->
          <img
            src="../assets/halal.jpeg"
            alt="Popup Image"
            style="width: 100%; border-radius: 8px; margin-bottom: 16px"
          />
          <!-- Terima kasih telah mengunjungi situs kami. Anda dapat melacak kiriman
          Anda dengan mudah di bawah ini. -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePopup"> Tutup </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <section class="hero">
      <div class="hero-text container">
        <h2>Lacak Kiriman Anda</h2>

        <v-form>
          <v-divider></v-divider>
          <v-text-field
            outlined
            v-model="awb"
            style="
              background-color: white;
              color: white !important;
              font-size: 24px;
            "
            single-line
            hide-details
            label="Masukan No Resi / No Referensi"
          />
          <br />
          <br />
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="info" rounded @click="HandleClick">
              <v-icon left>mdi-magnify</v-icon>Lacak
            </v-btn>
            <v-btn color="danger" rounded @click="Reset">
              <v-icon left>mdi-reload</v-icon>Reset
            </v-btn>
          </v-card-actions>
        </v-form>
        <div v-if="isLoading" class="loader"></div>
        <div v-if="lacak === '1'">
          <v-card title elevation="1" class="mt-5;mr-10">
            <v-card-title>
              <h4>Status Kiriman</h4>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="No Resi / No Referensi"
                    v-model="noawb"
                    readonly=""
                    outlined=""
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Status"
                    v-model="status"
                    readonly=""
                    outlined=""
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Keterangan"
                    v-model="ket"
                    readonly=""
                    outlined=""
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    label="Tanggal"
                    v-model="tanggal"
                    readonly=""
                    outlined=""
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Asal"
                    v-model="asal"
                    readonly=""
                    outlined=""
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Tujuan"
                    v-model="tujuan"
                    readonly=""
                    outlined=""
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    label="Layanan"
                    v-model="layanan"
                    readonly=""
                    outlined=""
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="Koli"
                    v-model="pieces"
                    readonly=""
                    outlined=""
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="Kg"
                    v-model="weight"
                    readonly=""
                    outlined=""
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    label="Vol"
                    v-model="vol"
                    readonly=""
                    outlined=""
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <div v-if="bukti">
                    <a
                      :href="'https://beos.ptppi.co.id/api/uploads/' + bukti"
                      target="_blank"
                      ><v-chip color="green" text-color="white"
                        >Bukti Pengiriman</v-chip
                      ></a
                    >
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <div v-if="lacak === '2'">
          <v-card title elevation="1" class="mt-5;mr-10">
            <v-card-title>
              <h4>Data Tidak Ditemukan</h4>
            </v-card-title>
          </v-card>
        </div>
      </div>
    </section>
    <section>
      <v-row>
        <v-col cols="12">
          <v-card>
            <IframeComponent :url="iframeUrl" />
          </v-card>
        </v-col>
      </v-row>
    </section>
    <div>
      <a :href="whatsappLink" class="whatsapp-button">
        <i class="fab fa-whatsapp"></i> Hubungi Kami di WhatsApp
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import IframeComponent from "../components/IframeComponent.vue";

export default {
  name: "HomeView",
  data() {
    return {
      isLoading: false,
      lacak: "0",
      awb: "",
      items: [],
      dialogLacak: false,
      status: "",
      noawb: "",
      ket: "",
      asal: "",
      tujuan: "",
      layanan: "",
      bukti: "",
      whatsappLink:
        "https://api.whatsapp.com/send?phone=628111886013&text=*Hai%20Admin%20Saya%20mau%20tanya%20tentang%20pengiriman%20barang%20nih%2C%20Saya%20dapat%20wa%20nya%20dari%20Web%20nya%20cplintasindo.co.id%20Terima%20kasih*",
      iframeUrl:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.4133726130162!2d106.94405107570746!3d-6.340472662036791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e699391fed569c3%3A0xef529c2844c542d3!2sGUDANG%20CPL%20PERJUANGAN!5e0!3m2!1sid!2sid!4v1717060093188!5m2!1sid!2sid", // Ganti dengan URL iframe yang diinginkan
      showImagePopup: true, // Control for popup image
    };
  },
  components: {
    IframeComponent,
  },

  mounted() {
    // Show popup on page load
    this.showImagePopup = true;

    // Auto-dismiss popup after 5 seconds
    setTimeout(() => {
      this.showImagePopup = false;
    }, 10000); // 10000 milliseconds = 10 seconds
  },
  methods: {
    closePopup() {
      this.showImagePopup = false; // Manually close popup
    },
    async Reset() {
      this.lacak = "0";
      this.awb = "";
    },
    async HandleClick() {
      this.isLoading = true; // Mengaktifkan loader
      try {
        const headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        const response = await axios.post(
          "https://beos.ptppi.co.id/api/lacak.php",
          {
            awb: this.awb,
            id_perusahaan: 1,
          },
          { headers }
        );
        this.response = response.data;
        console.log(response.data.data);

        if (response.data.status === "fail") {
          this.items = [];
          this.lacak = "2";
          this.isLoading = false; // Menonaktifkan loader
        } else {
          this.noawb = response.data.data.no_pengiriman;
          this.status = response.data.data.status_pengiriman;
          this.ket = response.data.data.keterangan;
          this.asal = response.data.data.asal_kiriman;
          this.tujuan = response.data.data.tujuan_kiriman;
          this.service = response.data.data.layanan;
          this.vol = response.data.data.volume_pengiriman;
          this.weight = response.data.data.berat_pengiriman;
          this.pieces = response.data.data.koli_pengiriman;
          this.tanggal = response.data.data.tgl_status_pengiriman;
          this.layanan = response.data.data.layanan;
          this.bukti = response.data.data.bukti_penerima;
          this.lacak = "1";
          this.items = response.data.data;
          this.isLoading = false; // Menonaktifkan loader
          //localStorage.setItem("authenticated",true)
          //this.$router.push('/home')
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  background-image: url("../assets/desktop-wallpaper-logistics.jpg");
  background-attachment: fixed;
  position: relative;
  height: 100vh;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .hero-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;

    h4 {
      text-transform: uppercase;
      font-size: 22px;
      padding-bottom: 4px;
    }

    h2 {
      font-size: 50px;
      @media (min-width: 550px) {
        font-size: 80px;
      }
    }

    hr:nth-child(2) {
      max-width: 365px;
      margin-bottom: 16px;
    }
    hr:nth-child(4) {
      height: 6px;
      background-color: #fff;
      border: none;
      max-width: 85px;
      margin-top: 16px;
    }
  }
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.whatsapp-button {
  background-color: #25d366; /* Warna latar belakang WhatsApp */
  color: #fff; /* Warna teks */
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px; /* Untuk membuat tombol berbentuk bulat */
  text-decoration: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Efek bayangan */
}

.whatsapp-button i {
  margin-right: 10px; /* Jarak antara ikon dan teks */
}
</style>

